import React from "react"
import Layout from "../layouts/zh"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ListMainLayout from '../layouts/listmain'

const IndexPage = (props) => (
  <Layout location={props.location}>
    <SEO title="Home" description="Channel.241的首页。您可以找到各种类别的喜爱文章。" lang="zh"/>
    <ListMainLayout data={props.data} langKey="zh"/>
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark (filter: {fields: {langKey: {eq: "zh"}}}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            tags
            author
            price
          }
          fields {
            slug
            langKey
          }
          excerpt
        }
      }
    }
  }
`

export default IndexPage
